var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-card',{staticClass:"search"},[_c('el-form',{staticClass:"demo-form-inline",attrs:{"size":"small","inline":true,"model":_vm.form}},[_c('el-form-item',{attrs:{"label":"角色名称"}},[_c('el-input',{attrs:{"placeholder":"角色名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.getList}},[_vm._v("查询")])],1)],1)],1),_c('el-card',[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.$refs.create.add()}}},[_vm._v("添加角色")])],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"align":"center","type":"index","index":_vm.indexMethod,"width":"80","label":"编号"}}),_c('el-table-column',{attrs:{"align":"center","prop":"name","label":"资源名称"}}),_c('el-table-column',{attrs:{"align":"center","prop":"description","label":"描述"}}),_c('el-table-column',{attrs:{"align":"center","width":"200px","prop":"createdTime","label":"添加时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm._f("timeFilter")(scope.row.createdTime)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$router.push({
                name: 'alloc-menu',
                params: {
                  roleId: scope.row.id
                }
              })}}},[_vm._v("分配菜单")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$router.push({
                name: 'alloc-resource',
                params: {
                  roleId: scope.row.id
                }
              })}}},[_vm._v("分配资源")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$refs.create.editor(scope.row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.del(scope.row.id)}}},[_vm._v("删除")])]}}])})],1),_c('div',{staticStyle:{"text-align":"right"}},[_c('el-pagination',{attrs:{"current-page":_vm.page.current,"page-sizes":[10, 20, 50, 100],"page-size":10,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){return _vm.$set(_vm.page, "current", $event)},"update:current-page":function($event){return _vm.$set(_vm.page, "current", $event)}}})],1),_c('create',{ref:"create",on:{"ok":_vm.getList}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }